import * as React from "react"
import { graphql } from "gatsby"
import localize from "../components/localize"
import { Media } from "../media"
import LanguageSelector from "../components/languageSelector"
import {
  accordionContent,
  section,
  heading,
  paragraph,
} from "./index.module.css"

// markup
const NotFoundPage = props => {
  return (
    <div className={accordionContent}>
      <h2 className={heading}>404</h2>
      <LanguageSelector pathname={props.pathname} langKey={props.langKey} />
      <Media at="sm">
        <section className={section}>
          <p className={paragraph}>
            Die von Ihnen gesuchte Seite wurde leider nicht gefunden. Bitte
            wählen Sie eine(n) Künstler*in aus dem unteren Kartenreiter aus.
          </p>
        </section>
      </Media>
      <Media greaterThan="sm">
        <section className={section}>
          <p className={paragraph}>
            Die von Ihnen gesuchte Seite wurde leider nicht gefunden. Bitte
            wählen Sie eine(n) Künstler*in aus dem rechten Kartenreiter aus.
          </p>
        </section>
      </Media>
    </div>
  )
}

export default localize(NotFoundPage)

export const query = graphql`
  {
    sanityExhibition(exName: { eq: "2021" }) {
      ...SanityExhibitionFragment
    }
    allSanityExhibition(sort: { fields: exName, order: ASC }) {
      ...SanityExhibitionConnectionFragment
    }
    allSanityArtist(
      filter: {
        participance: { eq: true }
        exhibition: { exName: { eq: "2021" } }
      }
      sort: { fields: lastName, order: ASC }
    ) {
      ...SanityArtistConnectionFragment
    }
    sanitySiteSettings {
      ...SanitySiteSettingsFragment
    }
  }
`
